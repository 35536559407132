import React, {useState} from "react";

function Signup() {
  const [email, setEmail] = useState("");
  return (
    <div id="mc-embed-signup">
      <form
        action="https://laserblaststudios.us1.list-manage.com/subscribe/post?u=20c84872a4bdc5e0150cffb06&amp;id=9b1f9026db"
        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
        target="_blank" noValidate>
        <div id="mc_embed_signup_scroll">
          <label id="mc-email-label" htmlFor="mce-EMAIL">Interested in our game? Subscribe for updates:</label>
          <input type="email"
                 value={email}
                 onChange={ (e) => { setEmail(e.target.value); } }
                 name="EMAIL"
                 className="email"
                 id="mce-EMAIL"
                 placeholder="email address"
                 required />
            <div style={{position:'absolute', left: '-5000px'}} aria-hidden="true">
              <input type="text"
                     name="b_20c84872a4bdc5e0150cffb06_9b1f9026db"
                     tabIndex={-1}
                     value="" />
            </div>
            <div id="mc-submit-button-container">
              <input type="submit"
                     value="Subscribe"
                     name="subscribe"
                     id="mc-embedded-subscribe"
                     className="button" />
            </div>
        </div>
      </form>
    </div>
  );
}

export default Signup;