import React, {useState} from 'react';
import 'styles/test.scss';
import test_a_image from 'images/test_a_image.jpg';

function PinTestA() {
  const [answer, setAnswer] = useState("");
  const [wrong, setWrong] = useState(false);

  const rightUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSeOEkjWwXcGJrRlN7A7NEZZ8SO_yh6D-N8SL2hTV23HNNNROg/viewform';

  const checkAnswer = () => {
    if (answer != "1018") {
      setWrong(true);
    } else {
      window.location.assign(rightUrl);
    }
  };

  return (
    <div className="test-body">
      <div className="test-container">
        <div className="test-header">
          <p className="test-title">Test Introduction</p>
          <p className="test-description">
            Imagine that your friend who loves making puzzles has asked you to withdraw money for them from their
            new bank account. Their PIN can be deduced using the hint they left on the scrap of paper below.
          </p>
        </div>
        <div className="test-content">
          <img src={test_a_image} width={480} height={436} />
          <div className="solution-input">
            <label>Enter your solution:</label>
            <input type="text"
                   value={answer}
                   onChange={ (e) => { setAnswer(e.target.value); } }
            />
            <button onClick={() => { checkAnswer(); }}>Submit Answer</button>
            {
              wrong && <p className="test-wrong">Sorry, this isn't the correct solution. Please try again!</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default PinTestA;
