import React, {useState} from 'react';
import CustomTable from 'components/CustomTable';
import entrance from 'images/townentrance_scaled.png';
import lake from 'images/lakefront_scaled.png';
import mountains from 'images/mountains_scaled.png';
import baysquatch from 'images/baysquatch_scaled.png';
import firetower from 'images/firetower_scaled.png';
import rowdyraccoon from 'images/rowdyraccoon_scaled.png';
import savmor from 'images/savmormotorinn_scaled.png';
import skylight from 'images/skylightcaves_scaled.png'
import diner from 'images/vinylparadise_scaled.png';
import Footer from 'components/Footer';

const tableData = [
  { elements: [{name: 'Ice Fishing Our #1 Attraction', src: lake}, {name: 'Hike The Famous Winterling Mountains', src: mountains}] },
  { elements: [{name: 'Historic Firetower', src: firetower}, {name: 'The Rowdy Raccoon Bar', src: rowdyraccoon}] },
  { elements: [{name: 'Sav-Mor Motor Inn', src: savmor}, {name: 'Vinyl Paradise Diner', src: diner}] },
  { elements: [{name: 'Skylight Caves', src: skylight}, {name: 'Bay-Squatch Investigations', src: baysquatch}] },
];

function Home() {
  const [image, setImage] = useState(entrance);
  const handle = (src: string) => {
    setImage(src);
  };
  return (
    <div>
      <header>
        <span id="title">Thin Ice</span>
        <span>An upcoming pixel art adventure game from Laserblast Studios</span>
      </header>
      <div id="content">
        <div id="game-synopsis">
          <p>Your husband is missing.</p>
          <p>Last seen: on an ice fishing trip to the quirky little resort town of Sandy Lake Village.</p>
          <p>You’ve heard that the locals believe in rumors of a lake monster, bigfoot, and UFO sightings. When you get there you’re sure it’ll just be something ordinary like a lost cell phone and a flat tire that your husband is dealing with. After all, those legends are just to tease the tourists, right?</p>
          <p>Right?</p>
          <p>&gt;<span className="blinking-cursor">|</span></p>
        </div>
        <h2>Welcome to the Sandy Lake Village Chamber of Commerce!</h2>
        <div className="img-container">
          <img className="main-img" src={image} width={768} height={432} />
        </div>
        <CustomTable data={tableData} emit={handle} />
        <div style={{height: '60px'}}></div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
