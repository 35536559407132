import React from 'react';
import 'styles/main.scss';
import 'styles/blink.scss';
import Home from 'pages/Home';
import {Route, Switch } from 'react-router-dom';
import PinTestA from 'pages/PinTestA';
import PinTestB from 'pages/PinTestB';

function App() {
  return (
    <Switch>
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/test/5CF9ECF2' component={PinTestA}></Route>
      <Route exact path='/test/63DDAB5B' component={PinTestB}></Route>
    </Switch>
  );
}

export default App;
