import React from "react";
import {TableRow} from "../types";

type CustomTableProps = {
  data: TableRow[];
  emit: (a: string) => void;
};

function CustomTable(props: CustomTableProps) {
  const {data, emit} = props;
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>
            <span>Businesses & Attractions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          data.map((r, i) => {
            return (
              <tr key={i}>
                {
                  r.elements.map(e => {
                    return (
                      <td key={e.name} style={{width: '50%'}}><a onClick={() => { emit(e.src); }}><span>{e.name}</span></a></td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

export default CustomTable;