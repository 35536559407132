import React from "react";
import Signup from "./Signup";

function Footer() {
  const date = new Date().getFullYear();
  return (
    <footer>
      <div className="footer-text">
        <span id="copyright">© {date} Laserblast Studios</span>
      </div>
      <Signup />
    </footer>
  );
}

export default Footer;